var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "e-filters",
        {
          attrs: {
            title: _vm.$t("Filtros"),
            searching: _vm.fetching,
            "search-text": _vm.$t("Pesquisar"),
          },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-store-combo", {
                        attrs: { id: "filters-store", required: "" },
                        on: {
                          input: function ($event) {
                            return _vm.storeChanged()
                          },
                        },
                        model: {
                          value: _vm.filters.storeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "storeId", $$v)
                          },
                          expression: "filters.storeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "order-price_table",
                          type: "vue-select",
                          label: _vm.$t("Caixa"),
                          placeholder: _vm.$t("Selecione"),
                          validation: "required",
                          options: _vm.payBoxesOptions,
                        },
                        model: {
                          value: _vm.filters.payBoxId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "payBoxId", $$v)
                          },
                          expression: "filters.payBoxId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "period",
                          type: "date-range-picker",
                          label: _vm.$t("Período"),
                          validation: "required",
                        },
                        model: {
                          value: _vm.filters.createdRangeDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "createdRangeDate", $$v)
                          },
                          expression: "filters.createdRangeDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "pay_box_statement-origins",
                          label: _vm.$t("Tipo"),
                          type: "vue-select",
                          multiple: "",
                          placeholder: _vm.$t("Selecione múltiplas opções"),
                          options: _vm.saleOriginOptions,
                        },
                        model: {
                          value: _vm.filters.origins,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "origins", $$v)
                          },
                          expression: "filters.origins",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "pay_box_statement-payment_methods",
                          label: _vm.$t("Forma de pagamento"),
                          type: "vue-select",
                          multiple: "",
                          placeholder: _vm.$t("Selecione múltiplas opções"),
                          options: _vm.getPaymentMethodsInPdvOptions,
                        },
                        model: {
                          value: _vm.filters.paymentMethods,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "paymentMethods", $$v)
                          },
                          expression: "filters.paymentMethods",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "payBoxSale",
            staticClass: "bordered mb-3",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.searched
                ? _vm.getEmptyTableMessage(
                    _vm.$tc("PAY_BOX_SALE.STATEMENT_SALE.NAME")
                  )
                : _vm.$t("Clique no botão Pesquisar para buscar o extrato"),
              fields: _vm.fields,
              items: _vm.statements,
              "tbody-tr-class": _vm.rowClass,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(cashBookId)",
                fn: function (row) {
                  return [
                    _c("e-router-link", {
                      attrs: {
                        to: {
                          name: "pdv-pay-box-closing",
                          params: { cashBookId: row.item.cashBookId },
                        },
                        title: _vm.$t("Visualizar resumo"),
                        value: row.item.cashBookId,
                      },
                    }),
                  ]
                },
              },
              {
                key: "cell(origin)",
                fn: function (row) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.getStatementOrigin(row.item)) + " "
                    ),
                  ]
                },
              },
              {
                key: "cell(value)",
                fn: function (row) {
                  return [
                    _c(
                      "span",
                      {
                        class:
                          row.item.operationType === "Debit"
                            ? "text-danger"
                            : "text-success",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(
                                row.item.operationType === "Debit"
                                  ? row.item.value * -1
                                  : row.item.value
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "cell(observation)",
                fn: function (row) {
                  return [
                    row.item.observation
                      ? [
                          _vm._v(" " + _vm._s(row.item.observation) + " "),
                          _c("br"),
                        ]
                      : _vm._e(),
                    row.item.salePayment && row.item.salePayment.nsu
                      ? [
                          _c("b", [_vm._v("NSU: ")]),
                          _vm._v(
                            " " + _vm._s(row.item.salePayment.nsu || "-") + " "
                          ),
                        ]
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c("e-grid-actions", {
                      attrs: {
                        "show-update": false,
                        "show-delete": false,
                        buttons: _vm.buttons(row.item),
                      },
                      on: {
                        print: function () {
                          return _vm.onPrint(row.item)
                        },
                      },
                    }),
                  ]
                },
              },
              {
                key: "custom-foot",
                fn: function () {
                  return [
                    _c("tr", [
                      _c(
                        "th",
                        { staticClass: "text-right", attrs: { colspan: "7" } },
                        [_vm._v(" Total ")]
                      ),
                      _c("th", { staticClass: "text-center" }, [
                        _vm._v(
                          " " + _vm._s(_vm._f("currency")(_vm.value)) + " "
                        ),
                      ]),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c("e-color-subtitled", {
                    attrs: {
                      colors: [
                        {
                          backgroundColor: "table-danger",
                          title: _vm.$t("Cancelado"),
                        },
                      ],
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "statement-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }