import { render, staticRenderFns } from "./PayBoxStatement.vue?vue&type=template&id=f44d2252&scoped=true&"
import script from "./PayBoxStatement.vue?vue&type=script&lang=js&"
export * from "./PayBoxStatement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f44d2252",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/echope/echope-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f44d2252')) {
      api.createRecord('f44d2252', component.options)
    } else {
      api.reload('f44d2252', component.options)
    }
    module.hot.accept("./PayBoxStatement.vue?vue&type=template&id=f44d2252&scoped=true&", function () {
      api.rerender('f44d2252', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/pdv/erp/pay-box-statement/PayBoxStatement.vue"
export default component.exports